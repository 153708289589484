import {observable} from "@nx-js/observer-util";
import {Services} from "../Services";
import {Network} from "../../network/Network";
import {EntityService} from "../entity/EntityService";
import {ICommunityModel} from "../../models/community/ICommunityModel";
import {ICommunityLiveModel} from "../../models/community/ICommunityLiveModel";
import {CommunityTextsService} from "./texts/CommunityTextsService";
import {CommunityCategoryService} from "./categories/CommunityCategoryService";
import {CommunityArticleService} from "./articles/CommunityArticleService";
import {ICommunityCategoryModel} from "../../models/community/category/ICommunityCategoryModel";
import {ICommunityArticleModel} from "../../models/community/article/ICommunityArticleModel";
import {INetworkComponent} from "../../network/types/INetworkComponent";
import {HttpStatus} from "../../network/status/HttpStatus";
import {ProductType} from "../../models/product/ProductType";
import {config} from "../../config";
import {ProductName} from "../../models/product/ProductName";
import {IDnsEntryModel} from "../../models/mail/domain/IDnsEntryModel";
import {Resources} from "../../resources/Resources";
import {CommunityFilesService} from "./files/CommunityFilesService";
import {CommunityType} from "../../models/enums/CommunityType";
import {CommunityFileType} from "../../models/community/files/CommunityFileType";
import {SedestralSsr} from "../../sedestral-interface-modules/sedestral-interface-component/ssr/SedestralSsr";
import {ICommunityManage} from "../../models/community/ICommunityManage";
import {ICommunityManageOrder} from "../../models/community/ICommunityManageOrder";
import {ErrorCode} from "../../network/status/error/ErrorCode";
import {NetworkSocket} from "../../network/socket/NetworkSocket";
import {WebSocketCommunityEventName} from "../../network/socket/names/WebSocketCommunityEventName";
import {CommunityVisitorsService} from "./visitors/CommunityVisitorsService";
import {ICommunityAppearanceModel} from "../../models/community/appearance/ICommunityAppearanceModel";
import {arrayRemove} from "../../sedestral-interface-modules/sedestral-interface-component/utilities/ArrayRemove";
import {CommunityPublishState} from "../../models/enums/CommunityPublishState";
import {
    SedestralMachine
} from "../../sedestral-interface-modules/sedestral-interface-component/machine/SedestralMachine";
import {
    ICommunityPartnerBlogModel,
} from "../../models/community/partner/ICommunityPartnerBlogModel";
import {CommunityAutopilotService} from "./autopilot/CommunityAutopilotService";
import {ICommunityAdminModel} from "../../models/community/ICommunityAdminModel";
import {CommunityWritingStyleService} from "./writingStyle/CommunityWritingStyleService";
import {ICommunityCreateStep0Model} from "../../models/community/ICommunityCreateStep0Model";
import {ICommunityCreateStep1Model} from "../../models/community/ICommunityCreateStep1Model";
import {ICommunityOptions} from "../../products/panel/components/components/community/types/ICommunityOptions";
import {CommunityKeywordsService} from "./keywords/CommunityKeywordsService";
import {CommunityKnowledgeService} from "./knowledge/CommunityKnowledgeService";
import {CommunityClustersService} from "./keywords/CommunityClustersService";
import {CommunityGscService} from "./gsc/CommunityGscService";
import {ICommunityPartnerBlogColumnIncoming} from "../../models/community/partner/ICommunityPartnerBlogColumnIncoming";
import {ICommunityPartnerBlogColumnOutgoing} from "../../models/community/partner/ICommunityPartnerBlogColumnOutgoing";

export class CommunityService {
    public static communities: ICommunityModel[] = observable([]);
    public static live: ICommunityLiveModel;

    public static contentSortEvents: ((order: ICommunityManageOrder) => void)[] = [];

    public static dispose(): void {
        this.contentSortEvents = [];

        CommunityTextsService.dispose();
        CommunityCategoryService.dispose();
        CommunityArticleService.dispose();
        CommunityFilesService.dispose();
        CommunityAutopilotService.dispose();
        CommunityKeywordsService.dispose();
        CommunityClustersService.dispose();
        CommunityKnowledgeService.dispose();
        CommunityVisitorsService.dispose();
        CommunityWritingStyleService.dispose();
        CommunityGscService.dispose();
    }

    public static init(): void {
        CommunityTextsService.init();
        CommunityCategoryService.init();
        CommunityArticleService.init();
        CommunityFilesService.init();
        CommunityAutopilotService.init();
        CommunityKeywordsService.init();
        CommunityClustersService.init();
        CommunityKnowledgeService.init();
        CommunityWritingStyleService.init();
        CommunityGscService.init();

        if (config.product != ProductName.toString(ProductType.LIVECHAT)) {
            window["CommunityService"] = this;
        }

        Network.on(ProductType.COMMUNITY, WebSocketCommunityEventName.COMMUNITY_CONTENT_SORT, (data) => {
            this.contentSortEvents.forEach(value => value(data))
        });

        Network.on(ProductType.COMMUNITY, WebSocketCommunityEventName.COMMUNITY_UPDATE, (data) => {
            this.store(data);
        });
    }


    /**
     * websocket
     */

    static onContentSort(component: INetworkComponent, func: (order: ICommunityManageOrder) => void) {
        this.contentSortEvents.push(func);
        component.onRemove(() => arrayRemove(this.contentSortEvents, func));
    }

    /**
     * http
     */

    public static async token(): Promise<ICommunityLiveModel> {
        try {
            let tokenRequest = await Network.post(ProductType.COMMUNITY, "/community/token", {
                languages: Resources.getLanguagesCandidateWidthDefault(Network?.router.language())
            });

            let live = this.storeLive(tokenRequest.data);
            EntityService.headersToken(tokenRequest.headers, false);

            return live;
        } catch (e) {
            console.log("erreur token: ")
            console.log(e)
            return undefined;
        }
    }

    public static async addLanguage(communityId: string, language: string, component?: INetworkComponent): Promise<ICommunityModel> {
        let request = await Network.put(ProductType.COMMUNITY, "/community/addLanguage", {
            communityId: communityId,
            language: language
        }, component);
        if (request.status == HttpStatus.OK) {
            return this.store(request.data);
        }

        return undefined;
    }

    public static async updateLogos(id: string, fileType: CommunityFileType, files: File[], component?: INetworkComponent): Promise<ICommunityModel> {
        let request = await Network.postFormData(ProductType.COMMUNITY, `/community/logos/update`, {
            files: files,
            fileType: fileType,
            communityId: id,
        }, component);

        if (request.status == HttpStatus.OK) {
            return this.store(request.data);
        }

        return undefined;
    }

    public static async updateAppearance(id: string, communityAppearanceModel: ICommunityAppearanceModel): Promise<ICommunityModel> {
        let request = await Network.postJson(ProductType.COMMUNITY, `/community/appearance/update/${id}`, communityAppearanceModel);
        if (request.status == HttpStatus.OK) {
            return this.store(request.data);
        }
        return undefined;
    }

    public static async updateSendEmailNotificationRanking(communityId: string, sendEmailNotificationRanking: boolean): Promise<ICommunityModel> {
        let request = await Network.get(ProductType.COMMUNITY, `/community/sendEmailNotificationRanking/update/${communityId}/${sendEmailNotificationRanking}`);
        if (request.status == HttpStatus.OK) {
            return this.store(request.data);
        }
        return undefined;
    }

    public static async updateContext(communityId: string, context: string, component?: INetworkComponent): Promise<ICommunityModel> {
        let request = await Network.post(ProductType.COMMUNITY, `/community/context/update`, {
            communityId: communityId,
            context: context
        }, component);
        if (request.status == HttpStatus.OK) {
            return this.store(request.data);
        }
        return undefined;
    }

    public static async createHost(communityId: string, host: string, component?: INetworkComponent): Promise<ICommunityModel> {
        let request = await Network.post(ProductType.COMMUNITY, `/community/host/create`, {
            communityId: communityId,
            host: host
        }, component);
        if (request.status == HttpStatus.OK) {
            return this.store(request.data);
        }
        return undefined;
    }

    public static async updateSettings(id: string, community: {
        brand: boolean,
        publishState: CommunityPublishState
    }): Promise<ICommunityModel> {
        let request = await Network.postJson(ProductType.COMMUNITY, `/community/settings/update/${id}`, community);
        if (request.status == HttpStatus.OK) {
            return this.store(request.data);
        }
        return undefined;
    }

    public static async checkRoute(language: string, categoryRoute: string, articleRoute: string, component?: INetworkComponent): Promise<boolean> {
        if (SedestralSsr.hasSsr()) {
            return true;
        }

        let request = await Network.post(ProductType.COMMUNITY, "/community/checkRoute", {
            language: language,
            categoryRoute: categoryRoute,
            articleRoute: articleRoute
        }, component);

        return request.status == HttpStatus.OK;
    }

    public static async findBySite(siteId: string, type: CommunityType, component?: INetworkComponent): Promise<ICommunityModel[]> {
        Services.handleErrors(component, [
            {status: HttpStatus.SERVICE_UNAVAILABLE, message: "none"},
        ]);

        let request = await Network.get(ProductType.COMMUNITY, `/community/site/${siteId}/${type}`, component);
        if (request && request.status == HttpStatus.OK) {
            return this.storeAll(request.data);
        }

        return undefined;
    }

    public static async findLimitsBySite(siteId: string, component?: INetworkComponent): Promise<{
        [key: number]: { [key: number]: number }
    }> {
        let request = await Network.get(ProductType.COMMUNITY, `/community/site/${siteId}/limits`, component);
        if (request && request.status == HttpStatus.OK) {
            return request.data;
        }
        return undefined;
    }

    public static async delete(communityId: string, component?: INetworkComponent): Promise<void> {
        await Network.delete(ProductType.COMMUNITY, `/community/${communityId}`, component);
    }

    public static async findById(communityId: string, language: string, component?: INetworkComponent): Promise<ICommunityModel> {
        Services.handleErrors(component, [
            {status: HttpStatus.NOT_FOUND, message: "none"}
        ]);

        let request = await Network.get(ProductType.COMMUNITY, `/community/${communityId}/${language}`, component);
        if (request && request.status == HttpStatus.OK) {
            return this.store(request.data);
        }

        return undefined;
    }

    public static async createStep0(communityCreateStep0Model: ICommunityCreateStep0Model, component?: INetworkComponent): Promise<ICommunityModel> {
        let request = await Network.postJson(ProductType.COMMUNITY, "/community/create/step/0", communityCreateStep0Model, component);
        if (request.status == HttpStatus.OK) {
            return this.store(request.data);
        }
        return undefined;
    }

    public static async createStep1(communityCreateStep1Model: ICommunityCreateStep1Model, component?: INetworkComponent): Promise<ICommunityModel> {
        let request = await Network.postJson(ProductType.COMMUNITY, "/community/create/step/1", communityCreateStep1Model, component);
        if (request.status == HttpStatus.OK) {
            return this.store(request.data);
        }
        return undefined;
    }

    public static async configExportNone(communityId: string, component?: INetworkComponent): Promise<ICommunityModel> {
        let request = await Network.post(ProductType.COMMUNITY, `/community/config/export/none/${communityId}`, {}, component);
        if (request.status == HttpStatus.OK) {
            return this.store(request.data);
        }
        return undefined;
    }

    public static async addRedactor(communityId: string, name: string, component?: INetworkComponent): Promise<ICommunityModel> {
        let request = await Network.post(ProductType.COMMUNITY, "/community/redactor", {
            communityId: communityId,
            name: name
        }, component);

        if (request.status == HttpStatus.OK) {
            return this.store(request.data);
        }

        return undefined;
    }

    public static async deleteRedactor(communityId: string, redactorId: string, component?: INetworkComponent): Promise<ICommunityModel> {
        let request = await Network.delete(ProductType.COMMUNITY, `/community/redactor/${communityId}/${redactorId}`, component);

        if (request.status == HttpStatus.OK) {
            return this.store(request.data);
        }

        return undefined;
    }

    public static async createCustomHost(communityId: string, customHost?: string, component?: INetworkComponent): Promise<IDnsEntryModel[]> {
        let request = await Network.post(ProductType.COMMUNITY, "/community/customHost", {
            communityId: communityId,
            customHost: customHost
        }, component);

        if (request.status == HttpStatus.OK) {
            return request.data;
        }

        return undefined;
    }

    public static async checkCustomHost(communityId: string, component?: INetworkComponent): Promise<IDnsEntryModel[]> {
        let request = await Network.get(ProductType.COMMUNITY, `/community/customHost/${communityId}`, component);
        if (request.status == HttpStatus.OK) {
            return request.data;
        }

        return undefined;
    }

    public static async findCustomHost(communityId: string, component?: INetworkComponent): Promise<IDnsEntryModel[]> {
        Services.handleErrors(component, [
            {status: HttpStatus.NOT_FOUND, message: "none"}
        ]);
        let request = await Network.get(ProductType.COMMUNITY, `/community/customHost/find/${communityId}`, component);
        if (request.status == HttpStatus.OK) {
            return request.data;
        }

        return undefined;
    }

    public static async checkInternalHost(communityId: string, hostname: string, communityType: CommunityType, component?: INetworkComponent): Promise<string> {
        Services.handleErrors(component, [
            {status: HttpStatus.BAD_REQUEST, errorCode: ErrorCode.HOST_EXIST, message: "none"},
            {status: HttpStatus.NOT_FOUND, errorCode: ErrorCode.HOST_EXIST}
        ]);

        let request = await Network.get(ProductType.COMMUNITY, `/community/checkInternalHost/${hostname}/${communityType}/${communityId}`, component);
        if (request.status == HttpStatus.OK) {
            return request.data;
        }

        return undefined;
    }

    public static async manage(communityId: string, component?: INetworkComponent): Promise<ICommunityManage> {
        Services.handleErrors(component, [
            {errorCode: ErrorCode.SUSPENDED, message: Resources.t("words.communitySuspendedDueToOfferInsufficiency")},
            {status: HttpStatus.NOT_FOUND, message: "none"},
        ]);
        let request = await Network.get(ProductType.COMMUNITY, `/community/manage/${communityId}`, component);
        if (request.status == HttpStatus.OK) {
            return this.storeManage(request.data);
        }
        return {
            community: undefined,
            articles: [],
        };
    }

    public static async selectPartnerBlog(communityId: string, remoteId: string, component?: INetworkComponent): Promise<ICommunityModel> {
        let request = await Network.post(ProductType.COMMUNITY, `/community/select/partner/blog/${communityId}/${remoteId}`, component);
        if (request.status == HttpStatus.OK) {
            return this.store(request.data);
        }
    }

    public static async getPartnerBlogColumns(communityId: string, component?: INetworkComponent): Promise<ICommunityPartnerBlogColumnIncoming[]> {
        let request = await Network.get(ProductType.COMMUNITY, `/community/list/partner/blog/columns/${communityId}`, component);
        if (request.status == HttpStatus.OK) {
            return request.data;
        }
    }

    public static async updatePartnerBlogColumns(communityId: string, columns: ICommunityPartnerBlogColumnOutgoing[], component?: INetworkComponent): Promise<ICommunityPartnerBlogColumnIncoming[]> {
        let request = await Network.postJson(ProductType.COMMUNITY, `/community/list/partner/blog/columns/${communityId}`, columns, component);
        if (request.status == HttpStatus.OK) {
            return request.data;
        }
    }

    public static async getLinks(communityId: string, component?: INetworkComponent): Promise<string[]> {
        let request = await Network.post(ProductType.COMMUNITY, `/community/links`, {
            communityId: communityId,
        }, component);
        if (request.status == HttpStatus.OK) {
            return request.data;
        }
    }

    public static async getListPartnerBlog(communityId: string, component?: INetworkComponent): Promise<ICommunityPartnerBlogModel[]> {
        let request = await Network.get(ProductType.COMMUNITY, `/community/list/partner/blog/${communityId}`, component);
        if (request.status == HttpStatus.OK) {
            return request.data;
        }
    }

    public static async getCommunitiesAdmin(siteId: string, component?: INetworkComponent): Promise<ICommunityAdminModel[]> {
        let request = await Network.get(ProductType.COMMUNITY, `/community/admin/${siteId}`, component);
        if (request.status == HttpStatus.OK) {
            return request.data;
        }
    }

    public static async resetCommunityStatsBySiteId(siteId: string, component?: INetworkComponent): Promise<boolean> {
        let request = await Network.get(ProductType.COMMUNITY, `/community/admin/resetStats/${siteId}`, component);
        if (request.status == HttpStatus.OK) {
            return true;
        }
    }

    /**
     * start
     */

    public static async start(options: ICommunityOptions, communityCreateStep0Model: ICommunityCreateStep0Model, component?: INetworkComponent): Promise<ICommunityModel> {
        let community = await this.createStep0(communityCreateStep0Model, component);
        if (community) {
            await Network.router.pages.reload(`/${options.basePath}/${community.id}/content/home`);
        }
        return community;
    }

    /**
     * links
     */

    public static linkCategory(category: ICommunityCategoryModel) {
        return config.domain + category.route;
    }

    public static linkArticle(category: ICommunityCategoryModel, article: ICommunityArticleModel) {
        return config.domain + category.route + article.route;
    }

    public static linkLocalArticle(category: ICommunityCategoryModel, article: ICommunityArticleModel) {
        return category.route + article.route;
    }

    /**
     * websocket
     */

    public static async startSocket(onRetry?: () => void): Promise<NetworkSocket> {
        let retry = async () => {
            if (onRetry) {
                onRetry();
            }

            await SedestralMachine.sleep(2500);

            if (await this.startSocket()) {
                await SedestralMachine.sleep(7000);
                window.location.reload();
            }
        }

        let socket = await Network.startWebSocket(ProductType.COMMUNITY, () => retry());
        if (!socket) {
            await retry();
        }

        return socket;
    }

    public static disposeSocket(): void {
        Network.getSocket(ProductType.COMMUNITY).disconnect(true);
    }

    public static async join(id: string) {
        if (id != null) {
            await Network.join(ProductType.COMMUNITY, id, WebSocketCommunityEventName.COMMUNITY_JOIN);
        }
    }


    public static leave(id: string) {
        Network.emit(ProductType.COMMUNITY, WebSocketCommunityEventName.COMMUNITY_LEAVE, id);
    }


    /**
     * store
     */

    public static storeManage(manage: ICommunityManage): ICommunityManage {
        manage.articles = CommunityArticleService.storeAll(manage.articles);
        return manage;
    }

    public static storeLive(live: ICommunityLiveModel): ICommunityLiveModel {
        live.community = this.store(live.community);
        live.categories = CommunityCategoryService.storeAll(live.categories);

        this.live = observable(live);
        return this.live;
    }

    public static storeAll(communities: ICommunityModel[]): ICommunityModel[] {
        for (let key in communities) {
            communities[key] = this.store(communities[key]);
        }
        return Services.storeAll(communities);
    }

    public static store(community: ICommunityModel): ICommunityModel {
        let storedCommunity = Services.get("id", this.communities, community.id);
        if (storedCommunity != undefined) {

            if (!community.autopilot && storedCommunity.autopilot) {
                community.autopilot = storedCommunity.autopilot;
            }

            if (!community.partner && storedCommunity.partner) {
                community.partner = storedCommunity.partner;
            }

            if (!community.context && storedCommunity.context) {
                community.context = storedCommunity.context;
            }

            if (!community.writingStyles && storedCommunity.writingStyles) {
                community.writingStyles = storedCommunity.writingStyles;
            }

            if (!community.redactors && storedCommunity.redactors) {
                community.redactors = storedCommunity.redactors;
            }
        }


        if (community.text) {
            community.text = CommunityTextsService.store(community.text);
        }

        if (community.logoFile) {
            community.logoFile = CommunityFilesService.store(community.logoFile);
        }

        if (community.footerFile) {
            community.footerFile = CommunityFilesService.store(community.footerFile);
        }

        if (community.faviconFile) {
            community.faviconFile = CommunityFilesService.store(community.faviconFile);
        }

        return Services.store("id", this.communities, community);
    }

    public static getHost(community: ICommunityModel): string {
        const endpoint = (community.hostCustomVerified && community.hostCustom ? community.hostCustom : community.host);
        return endpoint.startsWith("http") ? endpoint : "https://" + endpoint;
    }

    /**
     * SSR
     */

    public static setContextSsr(context: string): ICommunityLiveModel {
        return this.storeLive(JSON.parse(context));
    }
}